/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'grid-3x3': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 1.5A1.5 1.5 0 011.5 0h13A1.5 1.5 0 0116 1.5v13a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 14.5zM1.5 1a.5.5 0 00-.5.5V5h4V1zM5 6H1v4h4zm1 4h4V6H6zm-1 1H1v3.5a.5.5 0 00.5.5H5zm1 0v4h4v-4zm5 0v4h3.5a.5.5 0 00.5-.5V11zm0-1h4V6h-4zm0-5h4V1.5a.5.5 0 00-.5-.5H11zm-1 0V1H6v4z"/>',
    },
});
